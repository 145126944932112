import { listSwitchMarketing, listSwitchService } from '@/data/notification-setting';
import { ICustomerMarkettingNotification, IItemCustomerServiceNotification } from '@/interface/customer';
import { Divider, Switch } from 'antd';
import { GiStarShuriken } from 'react-icons/gi';

export interface IEmailCustomerNotificationSettingProps {
  service: IItemCustomerServiceNotification;
  marketing: ICustomerMarkettingNotification;
  setService: (value: IItemCustomerServiceNotification) => void;
  setMarketing: (value: ICustomerMarkettingNotification) => void;
}

export default function EmailCustomerNotificationSetting(props: IEmailCustomerNotificationSettingProps) {
  const { service, marketing, setMarketing, setService } = props;
  // console.log('marketing ', service);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <p className='mb-0 text-base font-semibold text-[#3C5984]'>Service Notification</p>
          <div className='flex flex-col gap-4 pl-2'>
            {listSwitchService.map((item, index) => (
              <div className='flex flex-row items-center justify-between gap-2' key={index}>
                <div className='flex items-center gap-2'>
                  <GiStarShuriken className='text-[#3C5984]' />
                  <p className='mb-0 text-sm text-[#3C5984]'>{item.title}</p>
                </div>
                <Switch
                  size='small'
                  checked={service[item.id as keyof IItemCustomerServiceNotification]}
                  onChange={e => setService({ ...service, [item.id]: e })}
                  className={service[item.id as keyof IItemCustomerServiceNotification] ? '!bg-[#1D3F75]' : ''}
                />
              </div>
            ))}
          </div>
        </div>
        <Divider style={{ borderColor: '#808080' }} className='!my-1' variant='dashed' />
        <div className='flex flex-col gap-2'>
          <p className='mb-0 text-base font-semibold text-[#3C5984]'>Marketing Notification</p>
          <div className='flex flex-col gap-4 pl-2'>
            {listSwitchMarketing.map((item, index) => (
              <div className='flex flex-row items-center justify-between gap-2' key={index}>
                <div className='flex items-center gap-2'>
                  <GiStarShuriken className='text-[#3C5984]' />
                  <p className='mb-0 text-sm text-[#3C5984]'>{item.title}</p>
                </div>
                <Switch
                  size='small'
                  checked={marketing[item.id as keyof ICustomerMarkettingNotification]}
                  onChange={e => setMarketing({ ...marketing, [item.id]: e })}
                  className={marketing[item.id as keyof ICustomerMarkettingNotification] ? '!bg-[#1D3F75]' : ''}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
