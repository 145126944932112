'use client';
import axiosClient from '@/helper/call-center';
import { PhoneOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Divider } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import LogoFooter from '../../../../../../public/images/FooterLogo.png';
import Right from '../../../../../../public/images/GroupRight.png';
import Left from '../../../../../../public/images/RectangleLeft.png';
import StarFooter from '../../../../../../public/images/StarFooter.png';
import Facebook from '../../../../../../public/svgs/Social/Facebook.svg';
import Instagram from '../../../../../../public/svgs/Social/Instagram.svg';

const Footer = () => {
  const location = typeof window !== 'undefined' ? localStorage.getItem('location_id') : null;

  const userQuery = useQuery({
    queryKey: ['location-detail'],
    queryFn: async () => {
      try {
        const result: any = await axiosClient.get(`store-branch/${location}`);

        return result;
      } catch (error) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const telByShop: any = userQuery?.data?.data?.phone;

  return (
    <div className='relative bg-gradient-to-b from-[#102647] to-[#1D3F75]'>
      <div className='hidden sm:block'>
        <div className='absolute left-0 top-0 z-10 flex h-[50%] w-full justify-center bg-cover bg-center sm:top-0 sm:w-[35%]'>
          <Image src={Left} alt='left' fill sizes='35vw' className='object-cover opacity-5' />
        </div>

        <div className='absolute bottom-0 right-0 z-10 h-[50%] w-full rotate-180 sm:!top-0 sm:w-[35%] sm:rotate-0'>
          <Image src={Right} alt='right' fill sizes='35vw' className='hidden object-cover opacity-5 sm:block' />
          <Image src={Left} alt='right' fill sizes='35vw' className='block object-cover opacity-5 sm:hidden' />
        </div>
      </div>
      <div className='absolute top-0 flex h-[124px] w-full items-start justify-between opacity-[0.12] sm:hidden'>
        <div className='relative h-full w-1/2'>
          <Image src={'/images/cloud-left.png'} alt='left' fill sizes='50vw' className='object-contain' />
        </div>
        <div className='relative h-full w-1/2'>
          <Image src={'/images/cloud-right.png'} alt='right' fill sizes='50vw' className='object-contain' />
        </div>
      </div>

      <div className='!z-20 mx-auto flex w-[min(1512px,80%)] pt-8 sm:pb-6 sm:pt-[64px]'>
        <div className='flex w-full flex-col justify-between gap-6 *:flex-1 *:text-center *:leading-[19px] *:text-white sm:flex-row sm:gap-4'>
          {/* 1 */}
          {/* <div className='z-50 flex flex-1 flex-col gap-4'>
            <div className='flex flex-col'>
              <p className='font-medium opacity-50'>Location 1</p>
              <p className='font-medium'>348 Matlock Rd, Ste 104, Mansfield, TX 76063</p>
              <p className='text-sm leading-[17px]'>Every day 9am - 7pm</p>

              <div className='flex flex-col items-center justify-center gap-2 md:flex-row'>
                <div className='flex items-center justify-center gap-1'>
                  <div className='!size-2 rounded-full bg-zelene-heavy-cream' />
                  <a href='tel:+(469) 693-3887' className='cursor-pointer !text-sm leading-[17px] text-white'>
                    (469) 693-3887
                  </a>
                </div>

                <div className='flex items-center justify-center gap-1'>
                  <div className='!size-2 rounded-full bg-zelene-heavy-cream' />

                  <a href='mailto:mansfield@zelenespa.com' className='cursor-pointer text-sm text-white'>
                    mansfield@zelenespa.com
                  </a>
                </div>
              </div>
            </div>
          </div> */}

          {/* 2 */}
          <div className='flex flex-1 items-center justify-center py-3 sm:py-0'>
            <Image
              alt='logo footer'
              width={170}
              height={133}
              src={LogoFooter}
              className='h-[133px] w-[170px] cursor-pointer'
            />
          </div>

          {/* 3 */}
          {/* <div className='z-50 flex flex-1 flex-col gap-4'>
            <div className='flex flex-col'>
              <p className='font-medium opacity-50'>Location 2</p>
              <p className='font-medium'>1400 Long Prairie Rd, Ste 130, Flower Mound, TX 75028</p>
              <p className='text-sm leading-[17px]'>Every day 9am - 7pm</p>

              <div className='flex flex-col items-center justify-center gap-2 md:flex-row'>
                <div className='flex items-center justify-center gap-1'>
                  <div className='!size-2 rounded-full bg-zelene-heavy-cream' />
                  <a href='tel:+(469) 693-3887' className='cursor-pointer !text-sm leading-[17px] text-white'>
                    (469) 316-0298
                  </a>
                </div>

                <div className='flex items-center justify-center gap-1'>
                  <div className='!size-2 rounded-full bg-zelene-heavy-cream' />

                  <a href='mailto:flowermound@zelenespa.com' className='cursor-pointer text-sm text-white'>
                    flowermound@zelenespa.com
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className='flex flex-col items-center justify-center gap-6 p-6 pb-8 sm:pb-[64px]'>
        <div className='flex gap-6 *:cursor-pointer *:font-[hneu] *:text-[16px] *:leading-[19px] *:text-[#FCDCAB]'>
          <Link href={'/gift'} className='flex items-center gap-1.5'>
            <span>Gift Card</span>
            <Image alt='star footer' width={16} height={16} src={StarFooter} className='h-[16px] w-[16px]' />
          </Link>

          <Link href={'/booking'} className='flex items-center gap-1.5'>
            <span>Book Now</span>
            <Image alt='star footer' width={16} height={16} src={StarFooter} className='h-[16px] w-[16px]' />
          </Link>
        </div>

        <div className='flex flex-wrap justify-center gap-4 *:cursor-pointer *:px-3 *:py-1.5 *:font-[hneu] *:text-base *:font-medium *:text-[#FCDCAB] sm:gap-6'>
          <Link href={'/about'}>About Us</Link>
          <Link href={'/locations'}>Locations</Link>
          <Link href={'/services'}>Services</Link>
          <Link href={'/contact'}>Contact Us</Link>
          <Link href={'/career'}>Career</Link>
          <Link href={'/blog'}>Blog</Link>
          <Link href={'/#faq'}>FAQ</Link>
          <Link href={'/policies'}>Terms & Service </Link>

          {/* <Link href={'/'}>Home</Link>
          <Link href={'/career'}>Shop</Link>
          <Link href={'/career'}>Promotion</Link>
          <Link href={'/booking'}>My booking</Link> */}
        </div>

        <div className='flex gap-8 *:cursor-pointer *:font-[hneu] *:text-base *:text-[#FCDCAB] sm:gap-6'>
          <a
            href={
              'https://www.facebook.com/zelenespa/?paipv=0&eav=AfZgmYGmrAtqDCLUfx2nBNq1Zjm06cyWqFvrW66BpLROVpZZ2JJlV4kF1DZhQixu9NM&_rdr'
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image src={Facebook} alt='Facebook Social' width={24} height={24} />
          </a>

          <a href={'https://www.instagram.com/zelenespa/'} target='_blank' rel='noopener noreferrer'>
            <Image src={Instagram} alt='Instagram Social' width={24} height={24} />
          </a>

          <a href={`tel:${telByShop}`} target='_blank' rel='noopener noreferrer'>
            <PhoneOutlined className='text-[24px]' />
          </a>

          {/* <Image src={Twitter} alt='Twitter Social' width={24} height={24} />
          <Image src={YouTube} alt='YouTube Social' width={24} height={24} />
          <Image src={LinkedIn} alt='LinkedIn Social' width={24} height={24} /> */}
        </div>
      </div>

      <Divider className='!my-0 !h-2 !border-white' />

      <p className='py-5 text-center font-[hneu] text-sm text-white sm:text-[16px]'>
        © 2024, Copyright Zelene Head Spa. All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
