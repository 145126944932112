import AddonCard from '@/components/elements/Card/AddonCard';
import ServiceCart from '@/components/elements/Card/ServiceCart';
import { Ardon, CartItemProps } from '@/interface/cart';
import { ID_FAKE_BOOKING } from '@/utils/global-variable';
import { Collapse, Divider } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const { Panel } = Collapse;
const CustomCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    border-radius: 8px !important;
  }

  .ant-collapse-expand-icon > span[aria-label='expanded'] svg {
    fill: black !important;
  }
`;

const CartItem: React.FC<CartItemProps> = ({ cart, onRemove, showAddonBtn, showWarning, isNotEdit, isOpenFirst }) => {
  const { service, addons, startTime } = cart || {};

  const amoutPlus = cart?.agreement?.answers;

  const totalPlus = amoutPlus
    ? amoutPlus
        .filter((val: any) => val.question === 'question6' || val.question === 'question7')
        .map((item: any) => item.amount_plus)
        .reduce((acc: any, curr: any) => acc + curr, 0)
    : 0;

  const dateTime = new Date();

  // const data = addons?.map((addon: any) => ({
  //   key: addon._id,
  //   label: addon.name,
  //   children: (
  //     <AddonCard
  //       hiddenBtn={showAddonBtn}
  //       addon={addon}
  //       addOnNote={'123'}
  //       addOnsSelected={'123'}
  //       isSelected={true}
  //       setAddOnsSelected={'123'}
  //       setAddOnNote={'321213'}
  //     />
  //   ),
  // }));
  const activeKeys = useMemo(() => {
    if (isOpenFirst) return [service._id];
    return [];
  }, [service._id]);
  return (
    <div className='flex flex-col gap-2' key={cart._id}>
      <ServiceCart
        showWarning={showWarning}
        startTime={cart.time_start}
        service={service}
        // nếu là isNotEdit thì không cho remove và nếu idCart là id_fake_booking thì không cho remove
        onRemove={isNotEdit || cart._id === ID_FAKE_BOOKING ? undefined : onRemove}
        totalPlus={totalPlus || 0}
        dateTime={dateTime}
      />

      {/* <Collapse accordion>
        {data.map((item: any) => (
          <Panel header={item.label} key={item.key}>
            {item.children}
          </Panel>
        ))}
      </Collapse> */}
      {!!addons && addons?.length > 0 && (
        <CustomCollapse className='w-full' defaultActiveKey={activeKeys}>
          <Panel key={service._id} header={<span className='font-medium'>Add-on for {service?.name}</span>}>
            {addons?.map((addon: Ardon) => (
              <div className='p-2' key={addon?._id}>
                <AddonCard
                  hiddenBtn={showAddonBtn}
                  addon={addon}
                  addOnNote={'123'}
                  addOnsSelected={'123'}
                  isSelected={true}
                  setAddOnsSelected={'123'}
                  setAddOnNote={'321213'}
                />
              </div>
            ))}
          </Panel>
        </CustomCollapse>
      )}

      <Divider className='!my-3' />
    </div>
  );
};

export default CartItem;
