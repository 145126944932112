import { CustomerNotifySettingAPI } from '@/api-client/customer/notify-setting';
import { initMarketing, initService } from '@/data/notification-setting';
import {
  ICategoryCustomerNotification,
  ICustomerMarkettingNotification,
  ICustomerNotifySetting,
  ICustomerNotifySettingCreate,
  IItemCustomerServiceNotification,
} from '@/interface/customer';
import { isChangeCustomerNotify } from '@/utils';
import { Button, Divider, Modal } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import EmailCustomerNotificationSetting from './email-setting';
import SmsCustomerNotificationSetting from './sms-setting';

export interface INotificationSettingProps {
  isModalOpen: boolean;
  setOpenModal: (value: boolean) => void;
  customer_id: string;
  onSubmit: (data: ICustomerNotifySettingCreate) => void;
  loading: boolean;
}

export default function NotificationSetting(props: INotificationSettingProps) {
  const { isModalOpen, setOpenModal, customer_id, onSubmit, loading } = props;

  const [emailService, setEmailService] = useState<IItemCustomerServiceNotification>(initService);
  const [emailMarketing, setEmailMarketing] = useState<ICustomerMarkettingNotification>(initMarketing);

  const [smsService, setSmsService] = useState<IItemCustomerServiceNotification>(initService);
  const [smsMarketing, setSmsMarketing] = useState<ICustomerMarkettingNotification>(initMarketing);
  const [isTwilioBlock, setIsTwilioBlock] = useState<boolean>(false);

  const [emailNotifyOld, setEmailNotifyOld] = useState<ICategoryCustomerNotification>();
  const [phoneNotifyOld, setPhoneNotifyOld] = useState<ICategoryCustomerNotification>();

  const [chooseTab, setChooseTab] = useState<'email' | 'sms'>('email');

  const handleClose = () => {
    setEmailNotifyOld(undefined);
    setPhoneNotifyOld(undefined);
    setEmailService(initService);
    setEmailMarketing(initMarketing);
    setSmsService(initService);
    setSmsMarketing(initMarketing);
    setOpenModal(false);
  };

  useEffect(() => {
    if (customer_id && isModalOpen) {
      CustomerNotifySettingAPI.findByCustomerId(customer_id).then(res => {
        if (res.data) {
          const notificationSetting: ICustomerNotifySetting = res.data;
          setEmailNotifyOld(notificationSetting?.email);
          setPhoneNotifyOld(notificationSetting?.sms);
          setEmailService(notificationSetting?.email?.service);
          setEmailMarketing(notificationSetting?.email?.marketing);
          setSmsService(notificationSetting?.sms?.service);
          setSmsMarketing(notificationSetting?.sms?.marketing);
          setIsTwilioBlock(notificationSetting?.isBlockByTwilio || false);
        }
      });
    }
  }, [customer_id, isModalOpen]);

  return (
    <Modal
      title={
        <div className='flex w-full items-start justify-start border-b border-slate-200'>
          <p className='mb-0 text-xl font-bold text-zelene-blue'>Notification Settings</p>
        </div>
      }
      open={isModalOpen}
      onClose={handleClose}
      onCancel={handleClose}
      footer={[]}
      centered
    >
      <div>
        <div className='flex py-1'>
          <div
            className={clsx(
              'flex cursor-pointer flex-row items-center rounded-sm px-6 py-1',
              // border border-[#1D3F75]
              chooseTab === 'email' ? 'bg-[#1D3F75]' : '',
            )}
            onClick={() => setChooseTab('email')}
          >
            <p className={clsx('mb-0 text-sm font-medium', chooseTab === 'email' ? 'text-white' : 'text-[#1D3F75]')}>
              Email
            </p>
          </div>
          <div
            className={clsx(
              'flex cursor-pointer flex-row items-center rounded-sm px-6 py-1',
              chooseTab === 'sms' ? 'bg-[#1D3F75]' : '',
            )}
            onClick={() => setChooseTab('sms')}
          >
            <p className={clsx('mb-0 text-sm font-medium', chooseTab === 'sms' ? 'text-white' : 'text-[#1D3F75]')}>
              Sms
            </p>
          </div>
        </div>
        <Divider style={{ borderColor: '#808080' }} className='!my-2' />
        {chooseTab === 'email' ? (
          <EmailCustomerNotificationSetting
            marketing={emailMarketing}
            service={emailService}
            setService={setEmailService}
            setMarketing={setEmailMarketing}
          />
        ) : (
          <SmsCustomerNotificationSetting
            isTwilioBlock={isTwilioBlock}
            marketing={smsMarketing}
            service={smsService}
            setService={setSmsService}
            setMarketing={setSmsMarketing}
          />
        )}
        <div className={clsx('flex justify-between gap-2', isTwilioBlock ? 'pt-5' : 'pt-8')}>
          <Button className='w-1/2 !rounded-full !py-5' onClick={handleClose}>
            Close
          </Button>
          <Button
            className={clsx(
              'w-1/2 !rounded-full !py-5',
              (isChangeCustomerNotify(
                emailService,
                emailMarketing,
                emailNotifyOld?.service as IItemCustomerServiceNotification,
                emailNotifyOld?.marketing as ICustomerMarkettingNotification,
              ) ||
                isChangeCustomerNotify(
                  smsService,
                  smsMarketing,
                  phoneNotifyOld?.service as IItemCustomerServiceNotification,
                  phoneNotifyOld?.marketing as ICustomerMarkettingNotification,
                )) &&
                '!bg-[#1D3F75] !text-white',
            )}
            disabled={
              !(
                isChangeCustomerNotify(
                  emailService,
                  emailMarketing,
                  emailNotifyOld?.service as IItemCustomerServiceNotification,
                  emailNotifyOld?.marketing as ICustomerMarkettingNotification,
                ) &&
                isChangeCustomerNotify(
                  smsService,
                  smsMarketing,
                  phoneNotifyOld?.service as IItemCustomerServiceNotification,
                  phoneNotifyOld?.marketing as ICustomerMarkettingNotification,
                )
              )
            }
            onClick={() => {
              onSubmit({
                email: {
                  marketing: emailMarketing,
                  service: emailService,
                },
                phone: {
                  marketing: smsMarketing,
                  service: smsService,
                },
              });
            }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
