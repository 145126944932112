import axiosClient from '@/helper/call-center';

export const CartApi = {
  getMemberCart(location: string) {
    return axiosClient.get(`/cart/member/${location}`);
  },
  getGuestCart(location: string) {
    return axiosClient.get(`/cart/guest/${location}`);
  },
  deleteGuestCartItem(id: string, location: string) {
    return axiosClient.delete(`/cart/guest/by-id/${location}/${id}`);
  },
  deleteMemberCartItem(id: string, location: string) {
    return axiosClient.delete(`/cart/member/by-id/${id}/${location}`);
  },
  checkValidCart(location: string) {
    return axiosClient.post(`/cart/member/validate/${location}`);
  },
};
