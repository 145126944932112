'use client';

import { CustomerNotifySettingAPI } from '@/api-client/customer/notify-setting';
import NotificationSetting from '@/components/custom/profile/notification-setting';
import ProfileBooking from '@/components/custom/profile/profile-booking';
import ProfileInfo from '@/components/custom/profile/profile-info';
import ProfilePoint from '@/components/custom/profile/profile-point';
import { useAuth } from '@/hooks/auth.hook';
import { ICustomerNotifySettingCreate } from '@/interface/customer';
import { UserRoleKey } from '@/utils/enum';
import { imgUrlTransferV1 } from '@/utils/global-func';
import { imgUrlTransfer } from '@/utils/transfer-image';
import { notification, Popover } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { ImProfile } from 'react-icons/im';
import { IoLogOut } from 'react-icons/io5';
import { MdDashboard } from 'react-icons/md';
import { VscSettings } from 'react-icons/vsc';
import Appointment from '../../../../../../public/svgs/Appointment.svg';
import Point from '../../../../../../public/svgs/Point.svg';

export const ProfileBtn = () => {
  const { logout, mutateProfile, profile } = useAuth();
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openBooking, setOpenBooking] = useState(false);
  const [openPoint, setOpenPoint] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [openNotifySetting, setOpenNotifySetting] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: ICustomerNotifySettingCreate) => {
    if (profile?._id) {
      setLoading(true);
      CustomerNotifySettingAPI.createByCustomer(data)
        .then(res => {
          notification.success({ message: 'Update notification setting successfully' });
          setOpenNotifySetting(false);
        })
        .catch(err => {
          notification.error({ message: 'Update notification setting failed' });
        })
        .finally(() => setLoading(false));
    }
  };

  const content = (
    <div>
      {((profile.role && profile.role !== UserRoleKey.CUSTOMER) || profile.isSuperAdmin) && (
        <Link
          href='/admin/time-slot'
          className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
          style={{ transition: 'all ease .3s' }}
        >
          <MdDashboard className='text-xl text-slate-800' />
          <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>dashboard</span>
        </Link>
      )}

      <div
        className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
        style={{ transition: 'all ease .3s' }}
        onClick={() => {
          setPopoverVisible(false); // Close the Popover when opening the modal
          setOpen(false);
          setOpenProfile(true);
        }}
      >
        <ImProfile className='text-xl text-slate-800' />
        <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>profile</span>
      </div>

      <div
        className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
        style={{ transition: 'all ease .3s' }}
        onClick={() => {
          setPopoverVisible(false); // Close the Popover when opening the modal
          setOpen(false);
          setOpenBooking(true);
        }}
      >
        <Image alt='appointment' src={Appointment} width={20} height={20} className='!size-[20px] object-contain' />
        <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>My Booking</span>
      </div>

      <div
        className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
        style={{ transition: 'all ease .3s' }}
        onClick={() => {
          setPopoverVisible(false); // Close the Popover when opening the modal
          setOpen(false);
          setOpenPoint(true);
        }}
      >
        <Image alt='appointment' src={Point} width={20} height={20} className='!size-[20px] object-contain' />
        <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>My Point</span>
      </div>

      {profile.role == UserRoleKey.CUSTOMER && (
        <div
          className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
          style={{ transition: 'all ease .3s' }}
          onClick={() => setOpenNotifySetting(true)}
        >
          <VscSettings className='text-xl text-slate-800' />
          <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>Notication settings</span>
        </div>
      )}

      <div
        className='flex cursor-pointer flex-row items-center gap-2 px-4 py-2 *:!text-zelene-blue hover:bg-slate-200'
        style={{ transition: 'all ease .3s' }}
        onClick={() => logout()}
      >
        <IoLogOut className='text-xl text-slate-800' />
        <span className='flex-1 text-left text-sm font-semibold capitalize text-slate-800'>logout</span>
      </div>
    </div>
  );

  return (
    <div className='relative z-50'>
      <div
        className='z-10 flex cursor-pointer flex-row items-center justify-center gap-2'
        onClick={() => setOpen(true)}
      >
        <div className='flex max-w-full flex-1 items-center justify-end gap-2 rounded-sm text-sm text-zelene-blue'>
          {/* <span className='font-semibold'>Hi</span> */}
          <span className='hidden font-bold sm:flex'>{profile?.first_name}</span>
        </div>
        <Popover
          content={content}
          trigger='click'
          placement='bottomRight'
          className='!z-[200]'
          open={popoverVisible}
          onOpenChange={visible => setPopoverVisible(visible)}
        >
          <div className='relative size-8'>
            <Image
              src={profile?.avatar ? imgUrlTransferV1(profile?.avatar) : imgUrlTransfer(undefined, 'avatar')}
              fill
              alt={profile?.first_name + ' ' + profile?.last_name}
              className='rounded-full object-cover shadow-md'
            />
          </div>
        </Popover>
        <ProfileInfo
          isModalOpen={openProfile}
          setOpenModal={setOpenProfile}
          profile={profile}
          mutateProfile={mutateProfile}
          urlBack='/login'
        />
        <ProfileBooking isModalOpen={openBooking} setOpenModal={setOpenBooking} profile={profile} />
        <ProfilePoint isModalOpen={openPoint} setOpenModal={setOpenPoint} profile={profile} />
        <NotificationSetting
          isModalOpen={openNotifySetting}
          setOpenModal={setOpenNotifySetting}
          customer_id={profile?._id}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};
