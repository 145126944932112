import { ICustomerMarkettingNotification, IItemCustomerServiceNotification } from '@/interface/customer';

export const initService: IItemCustomerServiceNotification = {
  newBookingByStaff: true,
  newBookingByCustomer: true,
  bookingConfirmed: true,
  bookingCancelled: true,
  bookingRemind: true,
  updateBooking: true,
};

export const initMarketing: ICustomerMarkettingNotification = {
  reviewBooking: true,
  notificationEvent: true,
};

export const listSwitchService = [
  {
    id: 'newBookingByStaff',
    title: 'Receive notifications for new bookings made by staff',
  },
  {
    id: 'newBookingByCustomer',
    title: 'Receive notifications for bookings created by yourself',
  },
  {
    id: 'bookingCancelled',
    title: 'Receive notifications for booking cancellations',
  },
  {
    id: 'bookingConfirmed',
    title: 'Receive notifications to confirm successful bookings',
  },
  {
    id: 'bookingRemind',
    title: 'Receive reminders for bookings scheduled for tomorrow',
  },
  {
    id: 'updateBooking',
    title: 'Receive notifications for booking updates',
  },
];
export const listSwitchMarketing = [
  {
    id: 'reviewBooking',
    title: 'Receive notifications for review requests',
  },
  {
    id: 'notificationEvent',
    title: 'Receive notifications for upcoming events',
  },
];
