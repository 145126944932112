import axiosClient from '@/helper/call-center';
import { ICustomerNotifySettingCreate } from '@/interface/customer';

export const CustomerNotifySettingAPI = {
  findByCustomerId(customerId: string) {
    return axiosClient.get(`/customer-notification-settings/find-by-customer-id/${customerId}`);
  },
  create(data: ICustomerNotifySettingCreate, id: string) {
    return axiosClient.post(`/customer-notification-settings/${id}`, data);
  },
  createByCustomer(data: ICustomerNotifySettingCreate) {
    return axiosClient.post('/customer-notification-settings/create-by-customer', data);
  },
};
