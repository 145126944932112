import axiosClient from '@/helper/call-center';
import { IBookingUpdate } from '@/interface/booking';
import { AxiosResponse } from 'axios';

export const bookingApi = {
  getBookingByTimeRange(data: { end_time: Date; start_time: Date; location?: string }) {
    return axiosClient.post('/booking/time-range', data);
  },
  getAvailableTime(data: {
    location_id: string;
    service_duration: number;
    addon_duration: number;
    date: string;
    staff: string;
  }) {
    return axiosClient.post('/booking/available-slots', data);
  },
  findByBillId(billId: string) {
    return axiosClient.get(`/booking/find-by-bill/${billId}`);
  },
  updateBooking(id: string, data: IBookingUpdate) {
    return axiosClient.put(`/booking/update-by-staff/${id}`, data);
  },
  cancelBooking(
    id: string,
    data: {
      reason: string;
      transactions?: any;
    },
  ) {
    return axiosClient.put(`/booking/cancel/${id}`, data);
  },
  confirmBooking(id: string) {
    return axiosClient.put(`/booking/confirm/${id}`);
  },
  completeBooking(id: string) {
    return axiosClient.put(`/booking/complete/${id}`);
  },
  updateAgreementImg(id: string, formData: FormData) {
    return axiosClient.put('/booking/update-agreement/' + id, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  findBookingByMember(memberId: string) {
    return axiosClient.get(`/booking/by-member/${memberId}`);
  },
  findById(id: string) {
    return axiosClient.get(`/booking/${id}`);
  },
  updateAgreement(data: any) {
    return axiosClient.post(`/booking/update-agreement-by-member`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  requestAgreement(id: string) {
    return axiosClient.post(`/booking/request-agreement/${id}`);
  },
  getAppointmentTotalTimeRangeByLocation(params: { startTime: string; endTime: string; location_id: string }) {
    return axiosClient.get(`/appointment-analysis/transaction-range-amount-by-location`, {
      params: params,
    });
  },
  getAppointmentChartTimeRangeByLocation(params: { startTime: string; endTime: string; location_id: string }) {
    return axiosClient.get(`/appointment-analysis/transaction-range-amount-by-location`, {
      params: params,
    });
  },
  getAppointmentStatus(params: { startTime: string; endTime: string; location_id: string; textSearch: string }) {
    return axiosClient.get(`/appointment-analysis/booking-status`, {
      params: params,
    });
  },

  getAppointmentTableView(params: {
    startTime: string;
    endTime: string;
    location_id: string;
    textSearch: string;
    status?: string;
    limit: number;
    current: any;
  }) {
    return axiosClient.get(`/appointment-analysis/appointment-listing`, {
      params: params,
    });
  },
  getAppointmentTableMethod(params: {
    startTime: string;
    endTime: string;
    location_id: string;
    textSearch: string;
    status?: string;
  }) {
    return axiosClient.get(`/booking/count-appointment-by-status`, { params: params });
  },

  createReview(payload: { billId: string; customerId: string; rate: number; description: string; code: string }) {
    const { billId, customerId, rate, description, code } = payload;

    return axiosClient.post(`/booking-review/${billId}/${customerId}/${code}`, {
      rate: rate,
      description: description,
    });
  },

  getInfoReviewByCode(code: string) {
    return axiosClient.get(`/booking-review/info-by-code/${code}`);
  },
  getReviewByBooking(bookingId: string) {
    return axiosClient.get(`/booking-review/by-booking/${bookingId}`) as Promise<
      AxiosResponse<
        {
          created_at: string;
          _id: string;
          time: string;
          date: string;
          ratings: number;
          description: string;
        }[],
        any
      >
    >;
  },
};
