import { Ardon } from '@/interface/cart';
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface IAddonCardProps {
  addOnsSelected: any;
  setAddOnsSelected: any;
  addOnNote?: any;
  setAddOnNote?: any;
  //list addon
  addon: Ardon;
  //show addon
  isSelected: any;
  check?: any;
  hiddenBtn?: boolean;
}

const AddonCard = (props: IAddonCardProps) => {
  const { addOnsSelected, setAddOnsSelected, addon, isSelected, check, hiddenBtn } = props;
  const { name, price } = addon;

  return (
    <div className='flex h-[82px] w-full flex-1 rounded-xl border-2 border-zelene-grey bg-zelene-cream p-4'>
      <div className='flex w-full flex-1 gap-3'>
        <div className='flex flex-1 items-center justify-between gap-4'>
          <div className='flex flex-1 flex-col justify-center gap-2'>
            <span className='font-hneu text-sm font-medium text-zelene-dark-blue'>
              {name}
              {addon.tag ? <span className='font-medium'>&nbsp;*{addon.tag}*</span> : ''}
            </span>

            <div className='flex items-center gap-3'>
              <span className='font-hneu text-sm font-medium text-zelene-red'>${price}&nbsp;USD</span>
            </div>
          </div>

          {!hiddenBtn &&
            isSelected &&
            (check ? (
              <Tooltip title='Click and remove item'>
                <button
                  onClick={() => {
                    setAddOnsSelected((prev: any) => prev.filter((item: any) => addon._id !== item._id));
                  }}
                  className='flex size-9 cursor-pointer items-center justify-center rounded-full border-2 border-[#E53935]'
                >
                  <span className='h-1 w-[20px] rounded-xl bg-[#E53935]' />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title='Click and add item'>
                <button
                  onClick={() => setAddOnsSelected([...addOnsSelected, addon])}
                  className='flex size-9 cursor-pointer items-center justify-center rounded-full bg-zelene-yellow transition-colors hover:!bg-[#bd8b24]'
                >
                  <PlusOutlined className='flex !size-6 justify-center !text-white' />
                </button>
              </Tooltip>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AddonCard;
