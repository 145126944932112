import { ICustomerMarkettingNotification, IItemCustomerServiceNotification } from '@/interface/customer';

export const isChangeCustomerNotify = (
  service: IItemCustomerServiceNotification,
  marketing: ICustomerMarkettingNotification,
  serviceOld: IItemCustomerServiceNotification,
  marketingOld: ICustomerMarkettingNotification,
) => {
  if (
    Boolean(service?.newBookingByStaff) !== Boolean(serviceOld?.newBookingByStaff) ||
    Boolean(service?.newBookingByCustomer) !== Boolean(serviceOld?.newBookingByCustomer) ||
    Boolean(service?.bookingCancelled) !== Boolean(serviceOld?.bookingCancelled) ||
    Boolean(service?.bookingConfirmed) !== Boolean(serviceOld?.bookingConfirmed) ||
    Boolean(service?.bookingRemind) !== Boolean(serviceOld?.bookingRemind) ||
    Boolean(service?.updateBooking) !== Boolean(serviceOld?.updateBooking) ||
    Boolean(marketing?.reviewBooking) !== Boolean(marketingOld?.reviewBooking) ||
    Boolean(marketing?.notificationEvent) !== Boolean(marketingOld?.notificationEvent)
  ) {
    return true;
  }
  return false;
};
